<template>
  <div class="work-material-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Work' }">作品管理</el-breadcrumb-item>
      <el-breadcrumb-item>媒材管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          媒材列表 <span class="unit">數量：{{ total }}</span>
        </p>

        <div>
          <el-button
            type="success"
            @click="$router.push({ name: 'CreateWorkMaterial' })"
            >新增媒材
          </el-button>
        </div>
      </table-title>

      <el-table :data="material_data">
        <el-table-column
          label="iD"
          prop="work_material_id"
          sortable
        ></el-table-column>
        <el-table-column
          label="中文媒材名稱"
          prop="name_zh"
          sortable
        ></el-table-column>
        <el-table-column
          label="英文媒材名稱"
          prop="name_en"
          sortable
        ></el-table-column>
        <el-table-column label="編輯" fixed="right" width="180">
          <template v-slot="{ row }">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="
                $router.push({
                  name: 'EditWorkMaterial',
                  params: { id: row.work_material_id },
                })
              "
              >編輯</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(row.work_material_id)"
              >刪除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import {
  getWorkMaterialsPagination,
  deleteWorkMaterial,
} from "@/api/work-material";

export default {
  name: "WorkMaterial",
  data() {
    return {
      material_data: [],
      total: 0,
      setup: {
        limit: 25,
        page: 0,
      },
    };
  },
  created() {
    if (this.$route.query.p) {
      this.setup.page = +this.$route.query.p;
    }
    this.handleGetWorkMaterials();
  },
  methods: {
    async handleGetWorkMaterials() {
      const { results, total } = await getWorkMaterialsPagination(this.setup);

      this.total = total;
      this.material_data = results;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetWorkTags();
    },
    handleDelete(work_material_id) {
      this.$confirm("是否刪除該關鍵字", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteWorkMaterial(work_material_id);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          await this.handleGetWorkMaterials();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
